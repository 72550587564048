.container {
    height: 100vh;
    background-position: center center;
    background-size: cover;
    overflow-y: auto;
}

.content {
    width: 100%;
}

.front {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 50px;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    border-top: 1px solid rgba(0, 0, 0, 0);
}

.text {
    display: block;
    margin: 0 auto;
    max-width: 800px;
    font-size: 16px;
}

.title {
    font-size: 38px;
    font-weight: 600;
    max-width: 800px;
    display: block;
    margin: 0 auto;
}

a {
    color: white;
}

.tuzzle_holder {
    display: block;
    max-width: 800px;
    margin: 10px auto;
}

.img_tuzzle {
    width: 300px;
    display: inline-block;
    margin-right: 15px;
}

@media only screen and (max-width: 600px) {
    .img_tuzzle {
        width: 90%;
        display: block;
        margin: 10px auto;
    }
}