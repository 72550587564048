header{
    width: 100%;
    margin-top: calc(100vh - 10vw);
    padding-top: 2vw;
    position: relative;
    vertical-align: middle;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

header .logo{
    width: 30vw;
    display: block;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    header{
        margin-top: calc(100vh - 20vw);
    }
    header .logo{
        width: 60vw;
    }
}