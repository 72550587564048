@font-face {
  font-family: "Epilogue-regular";
  src: local("Epilogue-regular"),
   url("./assets/fonts/Epilogue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Epilogue-black";
  src: local("Epilogue-black"),
   url("./assets/fonts/Epilogue-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Epilogue-bold";
  src: local("Epilogue-bold"),
   url("./assets/fonts/Epilogue-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Epilogue-medium";
  src: local("Epilogue-medium"),
   url("./assets/fonts/Epilogue-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Epilogue-regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000;
}

.wrapper{
  max-width: 800px;
  display: block;
  margin: 20px auto;
}

.text-center{
  text-align: center;
}

.white {
  color: #fff !important;
}

.black {
  color: #000 !important;
}
